import http from "@/api/Http";
const Device = {
  state: {
    timeList: JSON.parse(localStorage.getItem("timeList") || "[]"),
    typeList: JSON.parse(localStorage.getItem("typeList") || "[]"),
    unitList: JSON.parse(localStorage.getItem("unitList") || "[]"),
    nodeList: JSON.parse(localStorage.getItem("nodeList") || "[]"),
    checkList: JSON.parse(localStorage.getItem("checkList") || "[]"),
    projectList: JSON.parse(localStorage.getItem("projectList") || "[]"),
    purposeList: JSON.parse(localStorage.getItem("purposeList") || "[]"),
    classificationList: JSON.parse(localStorage.getItem("classificationList") || "[]"),
  },
  getters: {
    timeList(state){
      return state.timeList;
    },
    typeList(state) {
      return state.typeList;
    },
    unitList(state) {
      return state.unitList;
    },
    nodeList(state) {
      return state.nodeList;
    },
    checkList(state) {
      return state.checkList;
    },
    projectList(state) {
      return state.projectList;
    },
    purposeList(state) {
      return state.purposeList;
    },
    classificationList(state) {
      return state.classificationList;
    }
  },
  mutations: {
    addTodayTime(state, data) {
      state.timeList = data;
      localStorage.setItem("timeList", JSON.stringify(data));
    },
    addType(state, data) {
      state.typeList = data;
      localStorage.setItem("typeList", JSON.stringify(data));
    },
    addUnit(state, data) {
      state.unitList = data;
      localStorage.setItem("unitList", JSON.stringify(data));
    },
    addNode(state, data) {
      state.nodeList = data;
      localStorage.setItem("nodeList", JSON.stringify(data));
    },
    addCheck(state, data) {
      state.checkList = data;
      localStorage.setItem("checkList", JSON.stringify(data));
    },
    addCheckProject(state, data) {
      state.projectList = data;
      localStorage.setItem("projectList", JSON.stringify(data));
    },
    addPurpose(state, data) {
      state.purposeList = data;
      localStorage.setItem("purposeList", JSON.stringify(data));
    },
    addClassification(state, data) {
      state.classificationList = data;
      localStorage.setItem("classificationList", JSON.stringify(data));
    },
  },
  actions: {
    //获取服务器当天时间
    SaveTodayTime(context, data) {
      http
        .get("/waterBalance/timeCheck")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addTodayTime", res.msg);
          }
        })
        .catch(() => { });
    },
    // 获取用水单元
    SaveUnit(context, data) {
      http
        .get("/waterUnit")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addUnit", res.data);
          }
        })
        .catch(() => { });
    },
    // 获取测量点
    SaveNode(context, data) {
      http
        .get("/waterNode")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addNode", res.data);
          }
        })
        .catch(() => { });
    },
    // 获取设备类型
    SaveType(context, data) {
      http
        .get("/deviceType")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addType", res.data);
          }
        })
        .catch(() => { });
    },
    // 获取检测类型
    SaveCheck(context, data) {
      context.commit("addCheck", data);
    },
    //获取检测项
    SaveCheckProject(context, data) {
      http
        .get("/checkItem", { params: { typeId: 1 } })
        .then((res) => {
          if (res.code == 1) {
            context.commit("addCheckProject", res.data);
          }
        })
        .catch(() => { });
    },
    //获取水资源用途类型
    SavePurpose(context, data) {
      http
        .get("/waterUser")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addPurpose", res.data);
          }
        })
        .catch(() => { });
    },
    //获取水资源分类
    SaveClassification(context, data) {
      http
        .get("/classificationWater")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addClassification", res.data);
          }
        })
        .catch(() => { });
    },
  },
};
export default Device;
