<template>
  <a-menu
    theme="dark"
    mode="inline"
    :defaultSelectedKeys="[current]"
    :selectedKeys="[current]"
    @click="handleClick"
    @openChange="onOpenChange"
    :open-keys="openKeys"
    class="app-sider-menu"
  >
    <template v-for="item in this.routes[0].children">
      <a-sub-menu
        :key="item.path"
        v-if="item.children.length > 0 "
      >
        <span slot="title">
          <icon-font
            :type="item.icon"
            v-if="item.icon && item.icon.indexOf('icon-') == 0"
          />
          <span>{{ item.name }}</span>
        </span>
        <template v-for="menu in item.children">
          <a-menu-item :key="menu.path" v-if="menu.visiable == true">{{
            menu.name
          }}</a-menu-item>
        </template>
      </a-sub-menu>

      <a-menu-item :key="item.path" v-else-if="item.children.length == 0 ">
        <icon-font
          :type="item.icon"
          v-if="item.icon && item.icon.indexOf('icon-') == 0"
        />
        <span>{{ item.name }}</span>
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    openCurrent: {
      type: String,
    },
  },
  data() {
    return {
      current: this.$route.path,
      openKeys: ["/"],
      rootSubmenuKeys: [],
      flag: false,
    };
  },
  watch: {
    openCurrent: function (val, oldVal) {
      // 如果openCurrent改变就让他跳转
      this.flag = true;
      if (val.indexOf("alarm") > -1) {
        this.current = this.openCurrent;
        this.openKeys = ["/", "/alarms"];
        // 路由跳转
        this.$router.push(this.openCurrent);
      } else if (val.indexOf("setting") > -1) {
        this.current = this.openCurrent;
        this.openKeys = ["/", "/setting"];
        // 路由跳转
        this.$router.push(this.openCurrent);
      }
    },
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    routes() {
      return [...this.$store.getters.routes];
    },
  },
  created() {
    this.setRootKey();
    // 从告警提示按钮跳转偏差告警页面
  },
  methods: {
    setRootKey() {
      // 获得新菜单
      this.$store.dispatch("initRoutes");
      var routes = this.$store.getters.routes;
      routes[0].children.forEach((element) => {
        this.rootSubmenuKeys.push(element.path);
      });
    },
    onOpenChange(openKeys) {
      // 最新的key值
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    handleClick(e) {
      this.current = e.key;
      if (this.flag) {
        this.flag = false;
        this.$emit("clearPath");
      }
      if (e.key == "logout") {
        this.logout();
      } else {
        //如果key为路由则跳转
        if (e.key.indexOf("/") > -1) {
          console.log("查看e.key",e.key);
          this.$router.push(e.key);
        }
      }
    },
  },
  components: {},
};
</script>

<style lang="less" scoped></style>
